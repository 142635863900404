import React, {useMemo} from 'react'
import {QueryClient} from '@tanstack/react-query'
import {PersistQueryClientProvider, removeOldestQuery} from '@tanstack/react-query-persist-client'
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister'


const TanstackQueryProvider = ({children}) => {

    // const {logOut} = useAuth()

    const onError = error => {
        console.log('TANSTACK ERROR', error)
        // Unauthorized
        // if (error?.response?.status === 401) {
        //     console.log('TanstackQueryProvider caught 401')
        // logOut()
        // }
    }


    // https://tanstack.com/query/v4/docs/framework/react/guides/important-defaults

    const queryClient = useMemo(() => new QueryClient({
        defaultOptions: {
            queries: {
                networkMode: 'offlineFirst',
                refetchOnWindowFocus: false, // default true
                refetchOnReconnect: false, // default true
                // refetchOnMount: true, // default true
                retry: 2,
                staleTime: 60000, // default 0
                cacheTime: 1000 * 60 * 60 * 24, // 24 hours
                onError
            },
            mutations: {
                networkMode: 'offlineFirst',
                onError
            }
        }
    }), [])


    const persister = useMemo(() => createSyncStoragePersister({
        storage: window.localStorage,
        retry: removeOldestQuery
    }), [])



    // return (
    //     <QueryClientProvider client={queryClient}>
    //         {children}
    //     </QueryClientProvider>
    // )


    return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
                persister,
                maxAge: 1000 * 60 * 60 * 24, // 24 hours
                buster: '',
                dehydrateOptions: {
                    shouldDehydrateQuery: query => {
                        return Boolean(query.meta?.persist)
                    }
                }
            }}
            // onSuccess={() => console.log('Данные восстановлены')}
        >
            {children}
        </PersistQueryClientProvider>
    )


}

export default React.memo(TanstackQueryProvider)
