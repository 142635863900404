const FRONTEND_HOSTNAME_PROD = 'zatushno.com'
// const BACKEND_URL_DEV = "http://localhost:9000"
// const BACKEND_URL_PROD = "https://backend.zatushno.com"

export const IS_PRODUCTION = window.location.hostname === FRONTEND_HOSTNAME_PROD

// export const BACKEND_URL = "http://localhost:9000";
// export const BACKEND_URL = "https://backend.zatushno.com";

// export const BACKEND_URL = window.location.hostname === 'localhost' ?
//     BACKEND_URL_DEV :
//     BACKEND_URL_PROD


function getBackendUrl(address) {
    const isIpAddress = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(address)
    if (isIpAddress || address === 'localhost') {
        return `http://${address}:9000`
    } else {
        return `https://backend.${address}`
    }
}


// const FRONTEND_BACKEND_OBJ = {
//     'localhost': 'http://localhost:9000',
//     '10.147.18.230': 'http://10.147.18.230:9000',
//     'zatushno.com': 'https://backend.zatushno.com'
// }

// export const BACKEND_URL = FRONTEND_BACKEND_OBJ[window.location.hostname]
export const BACKEND_URL = getBackendUrl(window.location.hostname)


export const API = `${BACKEND_URL}/api`
export const GRAPHQL_ENDPOINT = `${BACKEND_URL}/graphql`
export const AUTH_TOKEN = 'jwt'
export const BEARER = 'Bearer'


export const TIMEZONE = 2


export const TRANSACTORS = {
    CASHBOOK: 3,
    ZEL: 1,
    ASI: 2
}

export const BANK_ACCOUNTS = {
    ZEL: {
        R: 1,
        S: 2
    }
}


export const TRANSACTOR_ROLES = {
    OWNER: 'Owner',
    WORKER: 'Worker'
}

export const CURRENCIES = {
    ZAT: 1,
    UAH: 2
}


export const TRANSACTION_OLD_TYPES = {
    PROFIT: 1,
    AD: 2,
    EXPENSE: 3,
    TRANSFER: 4
}


export const TRANSACTION_TYPES = {
    PROFIT: 5,
    EXPENSE: 6,
    REWARD: 7,
    DIVIDENDS: 12,
    REIMBURSEMENT: 8,
    INVESTMENT: 13,
    TRANSFER: 9,
    DEPOSIT: 10,
    WITHDRAWAL: 11
}

export const TRANSACTION_SUBTYPES = {
    PROFIT: {
        HOUSE: 1,
        AD: 2
    },
    REWARD: {
        INTEREST: 8
    },
    EXPENSE: {
        REFUND: 3,
        AD: 4,
        INTERNET: 5,
        COMMUNICATION: 6,
        REF: 7
    }
}

export const TRANSACTION_STATUSES = {
    SUCCESS: 1,
    PENDING: 2,
    CANCELED: 3
}


export const HOUSE_TYPES = {
    HOUSE: 1,
    APARTMENT: 2,
    LAND: 3
}


export const HOUSE_SUBTYPES = {
    COTTAGE: 1,
    APARTMENT: 2,
    ONE_ROOM: 3,
    TWO_ROOMS: 4,
    THREE_ROOMS: 5,
    ROOM: 7
}



export const HOUSE_STATUSES = {
    SUCCESS: 1,
    PENDING: 2,
    CANCELED: 3,
    BAD: 4,
    ADD: 5,
    INCOME: 16
}

export const REQUEST_STATUSES = {
    IN_PROCESS: 1,
    SUCCESS: 2,
    CANCELED: 3,
    PRIORITY: 10,
    DEPOSIT_PENDING: 11,
    PAYMENT_PENDING: 12,
    INCOME: 13,
    CONTINUE: 14
}

export const DEAL_TYPE = {
    DAILY: 3,
    MONTHLY: 1,
    SALE: 2
}

export const ROUTES = {
    DAILY: 'podobovo',
    DAILY_CARPATHIANS: 'vidpochynok-v-karpatah',
    DAILY_FULL: 'orenda-podobovo',
    MONTHLY: 'dovgostrokovo',
    SALE: 'prodazh',
    HOUSES: 'houses',
    ADD_HOUSE: 'add-house',
    USER: 'user',
    PROFILE: 'profile',
    PLACES: 'places',
    ADD_PLACE: 'add-place',
    SITE_MAP: 'site-map'
}


export const DEAL_TYPE_TO_ROUTE = {
    [DEAL_TYPE.DAILY]: ROUTES.DAILY,
    [DEAL_TYPE.MONTHLY]: ROUTES.MONTHLY,
    [DEAL_TYPE.SALE]: ROUTES.SALE
}


export const FEATURES = {
    FIREPLACE: 53,
    POOL: 66,
    VAT: 70,
    SAUNA: 71,
    ANIMALS: '91'
}

export const FEATURE_TYPES = {
    REVIEW: '10'
}

export const ANONYMOUS_USER_NAME = 'Новий контакт'


export const ADMIN_PHONE = '+380980675502'
export const ADMIN_TELEGRAM = 'YevgeniyBoyko'



export const BANK_NAMES = [
    {value: '1', label: 'Приватбанк'},
    {value: '2', label: 'Монобанк'},
    {value: '3', label: 'Ощадбанк'},
    {value: '4', label: 'ПУМБ'},
    {value: '5', label: 'SensBank'},
    {value: '6', label: 'Ізі банк'},
    {value: '7', label: 'А-банк'},
    {value: '8', label: 'Райффайзен Банк'},
    {value: '9', label: 'Правекс Банк'},
    {value: '10', label: 'Універсал банк'},
    {value: '11', label: 'Укрсиббанк'},
    {value: '12', label: 'ОТП банк'},
    {value: '13', label: 'Укргазбанк'},
    {value: '14', label: 'Креді Агріколь Банк'},
    {value: '15', label: 'Укрексімбанк'},
]


export const AVATAR_URL = '/avatar.jpg'


export const LOCAL_STORAGE_KEYS = {
    HOUSE_TABLE_VIEW: 'table_view'
}


export const NEW_YEAR_DATE = '2025-12-31'


export const GA4_ID = 'G-ESKYTL5FJ4'

export const USERS = {
    ZELATRIX: 1,
    ASIUS: 2
}


export const WINTER_MODE = false


export const SUITABLE_SORT = {
    ID: {
        DESC: 'id:desc',
        ASC: 'id:asc',
    },
    UPDATED: {
        DESC: 'updated_at:desc',
        ASC: 'updated_at:asc',
    },
    PRICE: {
        DESC: 'price:desc:last',
        ASC: 'price:asc:last',
    },
    PRICE_AVG: {
        DESC: 'avg_price:desc:last',
        ASC: 'avg_price:asc:last',
    },
    PLACES: {
        DESC: 'sleeping_places_total:desc:last',
        ASC: 'sleeping_places_total:asc:last',
    }
}
